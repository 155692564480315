httpHome = angular.module('webApp');

var api_base = "/api/";

httpHome.factory('httpHome', ['$http', function($http){

    return {
        getlistarCarrusel: function getlistarCarrusel() {
            return $http.get(api_base +  'carrusel/aleatorios');
        }
    }

}]);