webApp.controller('homeController', ['$scope', '$window', '$sce', '$timeout', 'httpHome',
    function ($scope, $window, $sce, $timeout, httpHome) {

        var homeVm = this;
        homeVm.contadorImagenesCarrusel = 0;
        homeVm.contadorIntentosCargarCarrucel = 0;
        homeVm.carruselTitulo = null;
        homeVm.carruselDescripcion = null;       
        homeVm.imagenesCarruselClass = {};      
        homeVm.listaImagenesCarrusel = [];
        
        var imagenesCarruselTrancicion = function () {

            if (homeVm.contadorImagenesCarrusel >= homeVm.listaImagenesCarrusel.length) {

                homeVm.contadorImagenesCarrusel = 0;

            }

            if (homeVm.listaImagenesCarrusel.length) {
                if (homeVm.listaImagenesCarrusel[homeVm.contadorImagenesCarrusel]) {

                    angular.forEach(homeVm.listaImagenesCarrusel, function (value, key) {
                        homeVm.listaImagenesCarrusel[key].activo = false;
                    });

                    var imagen = homeVm.listaImagenesCarrusel[homeVm.contadorImagenesCarrusel].imagen;

                    homeVm.imagenesCarruselClass = {
                        'background-image': 'url(' + imagen + ')',
                        'transition': 'background-image 2.5s linear',
                        '-webkit-transition': 'background-image 2.5s linear',
                        '-moz-transition': 'background-image 2.5s linear',
                        '-o-transition': 'background-image 2.5s linear',
                        '-ms-transition': 'background-image 2.5s linear'
                    };

                    homeVm.carruselTitulo = homeVm.listaImagenesCarrusel[homeVm.contadorImagenesCarrusel].titulo;

                    homeVm.carruselDescripcion = homeVm.listaImagenesCarrusel[homeVm.contadorImagenesCarrusel].descripcion;

                    homeVm.listaImagenesCarrusel[homeVm.contadorImagenesCarrusel].activo = true;

                    homeVm.contadorImagenesCarrusel = homeVm.contadorImagenesCarrusel + 1;

                    homeVm.listaImagenesCarruselShow = true;

                }

            }

            $timeout(imagenesCarruselTrancicion, 6000);


        };

        homeVm.trustAsHtml = function (html) {
            return $sce.trustAsHtml(html);
        };

        homeVm.imagenesCarrusel = function () {

            httpHome.getlistarCarrusel().then(function (resultado) {

                homeVm.listaImagenesCarrusel = resultado.data.carrusel;

                homeVm.imagenesCarruselClass = {
                    'transition': 'background-image 2s linear',
                    '-webkit-transition': 'background-image 2s linear',
                    '-moz-transition': 'background-image 2s linear',
                    '-o-transition': 'background-image 2s linear',
                    '-ms-transition': 'background-image 2s linear',
                    'background-image': 'url(' + resultado.data.carrusel[homeVm.contadorImagenesCarrusel].imagen + ')'
                };

                homeVm.carruselTitulo = resultado.data.carrusel[homeVm.contadorImagenesCarrusel].titulo;

                homeVm.carruselDescripcion = resultado.data.carrusel[homeVm.contadorImagenesCarrusel].descripcion;

                homeVm.listaImagenesCarrusel[homeVm.contadorImagenesCarrusel].activo = true;

                homeVm.contadorImagenesCarrusel = homeVm.contadorImagenesCarrusel + 1;

                $timeout(imagenesCarruselTrancicion, 6000);

                homeVm.listaImagenesCarruselCargandoShow = false;


            }).catch(function (resultado) {

                if (homeVm.contadorIntentosCargarCarrucel < 2) {
                    homeVm.contadorIntentosCargarCarrucel++;
                    homeVm.imagenesCarrusel();
                }

            });


        };

        homeVm.cambiarImagenCarrucelControles = function () {

            angular.forEach(homeVm.listaImagenesCarrusel, function (value, key) {
                homeVm.listaImagenesCarrusel[key].activo = false;
            });

            homeVm.contadorImagenesCarrusel = homeVm.contadorImagenesCarrusel + 1;

            if (homeVm.contadorImagenesCarrusel >= homeVm.listaImagenesCarrusel.length) {

                homeVm.contadorImagenesCarrusel = 0;

            }

            homeVm.imagenesCarruselClass = {
                'background-image': 'url(' + homeVm.listaImagenesCarrusel[homeVm.contadorImagenesCarrusel].imagen + ')'
            };

            homeVm.carruselTitulo = homeVm.listaImagenesCarrusel[homeVm.contadorImagenesCarrusel].titulo;

            homeVm.carruselDescripcion = homeVm.listaImagenesCarrusel[homeVm.contadorImagenesCarrusel].descripcion;

            homeVm.listaImagenesCarrusel[homeVm.contadorImagenesCarrusel].activo = true;

        };
      

        homeVm.imagenesCarrusel();

    }]);